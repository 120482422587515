<template>
  <div>
    <div class="container">
      <div class="row mb-5 container-value">
        <div
          class="title-value"
          v-if="selectedProduct && selectedProduct.description"
        >
          {{ selectedProduct?.description }}
        </div>
        <div class="set-value">
          <span>Quanto você deseja?</span>
          <input
            type="text"
            class="value-money"
            v-model.lazy="price"
            v-money="money"
          />
        </div>
        <div class="portion">
          <label v-if="selectedProduct?.id === inssProductId"
            >Em até {{ maxMonth }}x
            {{ this.portionValue.toFixed(2).split(".").join(",") }}</label
          >
          <span>Você entrará em análise de crédito</span>
        </div>
      </div>
      <div
        class="row align-items-baseline d-flex justify-content-center"
        @click="changeTab"
      >
        <button class="button-avanced">Avançar</button>
      </div>
    </div>
  </div>
  <AlertDanger ref="alertdanger"></AlertDanger>
</template>
<script>
import { mask } from "vue-the-mask";
import AlertDanger from "../components/AlertDanger.vue";
import { VMoney } from "v-money";
import { getApi } from "../services/AppService";
import { inssProductId } from "../utility/consts.js";

export default {
  name: "Page1",
  data() {
    return {
      step: 0,
      price: 0.0,
      prices: {},
      months: 0,
      seletedMonth: 0,
      seletedPrice: {},
      monthsList: [],
      minMonth: 0,
      maxMonth: 0,
      portionValue: 0.0,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      inssProductId: inssProductId,
    };
  },
  directives: {
    mask,
    money: VMoney,
  },
  watch: {
    price() {
      this.calcPortion();
    },
    focus(value) {
      if (value) {
        this.getPrices();
      }
    },
  },
  methods: {
    changeCategory(value) {
      this.step++;
      this.$emit("changeCategory", value);
    },
    async getPrices() {
      var prices = await getApi(
        `${process.env.VUE_APP_MEUCORBAN_CORBAN_API_URL}/api/v1/Category/${this.selectedProduct.categoryId}/Product/${this.selectedProduct.id}/PriceList`
      );
      var monthsList = prices.data.map((prices) => prices.qtdInstallments);
      this.monthsList = monthsList;
      this.maxMonth = Math.max(...monthsList);
      this.minMonth = Math.min(...monthsList);
      this.months = Math.max(...monthsList);
      console.log(this.maxMonth);
      this.prices = prices;
    },
    message(text, status) {
      this.$refs.alertdanger.message(text, status);
    },
    calcPortion() {
      if (this.prices.data !== undefined) {
        var seletedPrice = this.prices.data.filter(
          (price) => price.qtdInstallments === this.maxMonth
        );
        this.seletedPrice = seletedPrice[0];
        let floatPrice = parseFloat(
          this.price
            .replaceAll("R$ ", "")
            .replaceAll(".", "")
            .replaceAll(",", ".")
        );

        console.log(floatPrice);
        this.portionValue = floatPrice * this.seletedPrice.coefficient;
      }
    },
    changeTab() {
      let floatPrice = parseFloat(
        this.price
          .replaceAll("R$ ", "")
          .replaceAll(".", "")
          .replaceAll(",", ".")
      );

      console.log(floatPrice);
      if (floatPrice > 0) {
        localStorage.setItem(
          "prices",
          JSON.stringify({
            seletedPrice: this.seletedPrice,
            deadline: this.months,
            plotValue: this.portionValue,
          })
        );
        this.$emit("changeTab", "value");
      } else {
        this.message("Selecione uma categoria para continuar", "danger");
      }
    },
  },
  props: {
    selectedProduct: Object,
    focus,
  },
  components: {
    AlertDanger,
  },
};
</script>
<style scoped>
.list-container {
  width: calc(100% - 40px);
  padding-left: 20px;
  padding-right: 20px;
}

.title-value {
  margin-top: 20px;
  padding: 25px 40px;
  max-width: fit-content;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;

  border-radius: 40px;

  background-color: #5775d0;
  color: #ffffff;
}

.title-container {
  width: 100%;
  display: flex;
  padding-left: 50px;
}

.title-container h1 {
  color: #5775d0;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
}

.main-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 100px);

  justify-content: space-evenly;
  align-items: center;
}

.container {
  padding: 36px;
}

.button-avanced {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 35px;
  color: #ffffff;
  outline: none;
  border: 0;
  padding: 10px;
  width: 100%;
  background-color: #5775d0;
  border-radius: 0px 20px 20px 20px;
  margin-top: 30px;
}

.container-value {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 45px;
  width: 312px;
  height: 297px;

  background: #ffffff;
  border-radius: 12px;
}

.set-value {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  width: 270px;
  border-bottom: 1px solid #8c8c8c;
}

.value-money {
  width: 280px;
  margin-bottom: 10px;
  border: none;
  outline: none;
  color: #8c8c8c;
  font-style: normal;
  font-weight: 707;
  font-size: 36px;
  text-align: center;

  padding-left: 0px;
}

.portion {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.portion label {
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #5775d0;
}

.portion span {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: #000000;
}
</style>

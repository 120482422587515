import { createApp } from "vue";
import VueFeather from "vue-feather";
import App from "./App.vue";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const app = createApp(App);

app.component(VueFeather.name, VueFeather);
app.mount("#app");

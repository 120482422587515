<template>
  <div class="container">
    <div class="logo-container">
      <div class="col-12 img-hand">
        <img src="../assets/img/HandRequest.svg" />
      </div>

      <div class="col-12 img-container">
        <img class="image-logo" :src="this.imgLink" />
        <div class="title-container">
          <span class="branch-name">{{ this.companyName }}</span>
          <span class="class-matriz">{{ this.branchName }}</span>
        </div>
      </div>
    </div>
    <div class="button-avanced">
      <button @click="changeTab">Avançar</button>
    </div>

    <div class="row align-items-center mb-5 cta-container">
      <carousel :items-to-show="6" :autoplay="1000" :wrap-around="true">
        <slide
          class="slide"
          style="width: 50px"
          v-for="slide in banklistImg"
          :key="slide"
        >
          <img class="img-carousel" :src="slide.bankImg" />
        </slide>
      </carousel>

      <div class="text-couresel">
        <span>Correspondente autorizado</span>
      </div>
    </div>
  </div>
</template>
<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

export default {
  data() {
    return {
      banklistImg: [
        {
          name: "Safra",
          bankImg: window.location.origin + "/banks/Safra.svg",
        },
        {
          name: "C6",
          bankImg: window.location.origin + "/banks/BancoC6.svg",
        },
        {
          name: "PAN",
          bankImg: window.location.origin + "/banks/PAN.svg",
        },
        {
          name: "Daycoval",
          bankImg: window.location.origin + "/banks/Daycoval.svg",
        },
        {
          name: "Bradesco",
          bankImg: window.location.origin + "/banks/Bradesco.svg",
        },
        {
          name: "Inter",
          bankImg: window.location.origin + "/banks/Inter.svg",
        },
        {
          name: "BMG",
          bankImg: window.location.origin + "/banks/BMG.svg",
        },
        {
          name: "BRB",
          bankImg: window.location.origin + "/banks/BRB.svg",
        },
        {
          name: "barigui",
          bankImg: window.location.origin + "/banks/barigui.svg",
        },
        {
          name: "Banrisul",
          bankImg: window.location.origin + "/banks/Banrisul.svg",
        },
        {
          name: "Parana",
          bankImg: window.location.origin + "/banks/Parana.svg",
        },
        {
          name: "Ole",
          bankImg: window.location.origin + "/banks/Ole.svg",
        },
        {
          name: "Banco Master",
          bankImg: window.location.origin + "/banks/BancoMaster.svg",
        },
        {
          name: "Itau",
          bankImg: window.location.origin + "/banks/Itau.svg",
        },
        {
          name: "Facta",
          bankImg: window.location.origin + "/banks/Facta.svg",
        },
      ],
    };
  },
  props: {
    imgLink: String,
    companyName: String,
    branchName: String,
  },
  components: {
    Carousel,
    Slide,
  },
  methods: {
    changeTab() {
      this.$emit("changeTab", "category");
    },
  },
  mounted() {},
};
</script>
<style scoped>
.container {
  padding: 36px;
  display: flex;
  gap: 65px;
  flex-direction: column;
  align-items: center;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-container {
  display: flex;
  max-width: fit-content;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.branch-name {
  position: relative;
  font-weight: 800;
  font-size: 20px;
  color: #5775d0;
}

.cta-container {
  width: 340px;
  height: 50px;
  text-align: center;
}

.img-container {
  display: flex;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  flex-direction: column;
  background-color: none;
  align-items: center;
  justify-content: center;
}

.class-matriz {
  font-weight: 400;
  font-size: 16.5px;
  line-height: 21px;
  color: #5775d0;
}

.image-logo {
  width: 110px;
  height: 110px;
  border-radius: 50%;
}

.img-hand {
  display: flex;
  background-color: none;
}

.img-hand img {
  margin-left: 35px;
  height: 220px;
  width: auto;
}

.img-carousel {
  width: 45px;
  height: auto;
}

.slide {
  margin-top: 2px;
}

.text-couresel {
  margin-top: 14px;
  text-align: right;
}

.text-couresel span {
  font-style: normal;
  font-weight: 707;
  font-size: 13px;
  line-height: 17px;

  color: #8c8c8f;
}

.button-avanced {
  width: 100%;
  display: flex;
  justify-items: center;
  justify-content: center;
}
.button-avanced button {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 35px;
  color: #ffffff;
  outline: none;
  border: 0;
  padding: 10px;
  width: 80%;
  max-width: 300px;
  background-color: #5775d0;
  border-radius: 0px 20px 20px 20px;
}
@media (max-width: 360px), (max-height: 600px) {
  .container {
    padding: 36px;
    gap: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .img-hand {
    display: flex;
    background-color: none;
  }
  .cta-container {
    width: 300px;
  }

  .img-hand img {
    margin-left: 25px;
    height: 150px;
    width: auto;
  }
  .img-container {
    margin-top: 20px;
    display: flex;
    width: 130px;
    height: 120px;
    border-radius: 50%;
    flex-direction: column;
    background-color: none;
    align-items: center;
    justify-content: center;
  }
  .image-logo {
    width: 90px;
    height: 90px;
    border-radius: 50%;
  }
  .button-avanced button {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 35px;
    color: #ffffff;
    outline: none;
    border: 0;
    padding: 5px;
    width: 80%;
    max-width: 300px;
    background-color: #5775d0;
    border-radius: 0px 20px 20px 20px;
  }
}
</style>

<template >
    <div>
        <div class="container">
            <div class="row title">
                <h1>Sua proposta foi cadastrada com sucesso!!!</h1>
            </div>
            <div class="row logo-container">
                <img src="../assets/img/HandRequest-ok.svg" />
            </div>
            <div class="row container-value">
                <div class="container-app">
                    <span>Instale o app para acompanhar o seu pedido:</span>
                    <img src="../assets/img/logo-corban.svg" />
                </div>
                <div class="link-app">
                    <a href="https://app.meucorban.app/PbhJPI" >
                        <img src="../assets/img/Gplay.svg"/>
                    </a>
                    <a href="https://app.meucorban.app/PbhJPI" >
                        <img src="../assets/img/Apple.svg"/>
                    </a>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { mask } from 'vue-the-mask'
export default {
    directives: { mask },
    data() {
        return {
            CEP: "",
            Rua: "",
            Número: "",
            Complemento: "",
            Bairro: "",
            Cidade: "",
            Estado: "",
            step: 0,
            numberBranch: ""
        }
    },
    props: {
        branchdata: Array
    },
    methods: {
        buttonWhats() {
            if (!this.branchdata?.Whatsapp && !this.branchdata.Company?.Whatsapp) {
                this.step = 1;
            } else {
                let whatsAppNumber = this.branchdata?.Whatsapp ?? this.branchdata.Company?.Whatsapp;

                whatsAppNumber = whatsAppNumber.split(' ').join('');

                whatsAppNumber = whatsAppNumber.replaceAll('(', '');
                whatsAppNumber = whatsAppNumber.replaceAll(')', '');
                whatsAppNumber = whatsAppNumber.replaceAll('-', '');
                this.numberBranch = `https://wa.me/55${whatsAppNumber}`;
            }
        }
    },
    mounted() {
        this.buttonWhats();
    }
}
</script>
<style scoped>
.logo-container {
    position: relative;
    top: 0;
   
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.logo-container img {
    max-width: calc(100vw - 100px)
}

.container {
    padding: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
}

.title h1 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 707;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    
    color: #5775D0;
}

.tlt {
    position: relative;
    right: 110px;
    bottom: 20px;
}


.container-value {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 45px;
    width: 312px;
    height: 250px;

    background: #FFFFFF;
    border-radius: 12px;
}

.container-app {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;

}
.container-app span{
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    color: #5775D0;
    text-align: center;
}

.container-app img {
    width: auto;
    height: 39.2px;
}

.link-app {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.link-app img {
    width: auto;
    height: 45px;
}
</style>
<template>
  <loading :active="this.isLoading" :is-full-page="true" />
  <div class="request-tabs {{activeTab}}">
    <Header
      v-if="activeTab !== 'start' && origin !== 'invite'"
      :imgLink="this.imgLink"
      @offLoading="isLoading"
      :branchName="this.branchName"
      :companyName="this.companyName"
    />

    <HeaderInvite
      v-if="
        activeTab !== 'start' &&
        activeTab !== 'start-invite' &&
        origin == 'invite'
      "
      :imgLink="this.imgLink"
      @offLoading="isLoading"
      :branchName="this.branchName"
      :companyName="this.companyName"
    />

    <!-- PAGINA 1 -->
    <div class="tab" v-bind:class="{ active: activeTab == 'start' }">
      <Page_1
        @changeTab="changeTab"
        :companyName="this.companyName"
        :imgLink="this.imgLink"
        :branchName="this.branchName"
      />
    </div>
    <!-- PAGINA 2 -->
    <div class="tab" v-bind:class="{ active: activeTab == 'category' }">
      <Page_2
        @changeTab="changeTab"
        :selectedProduct="selectedProduct"
        @changeCategory="changeCategory"
        v-if="selectedProduct !== null"
        :products="this.products"
        :focus="activeTab == 'category'"
      />
    </div>

    <div class="tab" v-bind:class="{ active: activeTab == 'value' }">
      <Page_3
        @changeTab="changeTab"
        :imgLink="this.imgLink"
        :origin="this.origin"
      />
    </div>

    <div class="tab" v-bind:class="{ active: activeTab == 'endhot' }">
      <Page_4 v-if="this.branchDados" :branchdata="this.branchDados" />
    </div>

    <!--PÁGINAS INVITE-->
    <div
      class="tab-invite"
      v-bind:class="{ active: activeTab == 'start-invite' }"
    >
      <Page_1_Invite
        @changeTab="changeTab"
        :companyName="this.companyName"
        :imgLink="this.imgLink"
      />
    </div>

    <div
      class="tab-invite"
      v-bind:class="{ active: activeTab == 'contact-invite' }"
    >
      <Page_3
        @changeTab="changeTab"
        :imgLink="this.imgLink"
        :origin="this.origin"
      />
    </div>

    <div
      class="tab-invite"
      v-bind:class="{
        active: activeTab == 'endHot-invite' && origin == 'invite',
      }"
    >
      <Page_4_Invite />
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Page_1 from "../components/Page_1.vue";
import Page_2 from "../components/Page_2.vue";
import Page_3 from "../components/Page_3.vue";
import Page_4 from "../components/Page_4.vue";
import HeaderInvite from "../components/Invite/HeaderInvite.vue";
import Page_1_Invite from "../components/Invite/Page_1_Invite.vue";
import Page_4_Invite from "../components/Invite/Page_4_Invite.vue";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { getOwnerCredentials, getApi } from "../services/AppService";
export default {
  name: "MainView",

  components: {
    Page_1,
    Page_2,
    Page_3,
    Page_4,
    HeaderInvite,
    Page_1_Invite,
    Page_4_Invite,
    Loading,
    Header,
  },
  data() {
    return {
      activeTab: "start",
      category: "",
      category_id: null,
      access_token: null,
      company_pic: null,
      company_id: null,
      branch_id: null,
      product: {},
      product_id: null,
      selectedProduct: null,
      isLoading: true,
      branchName: null,
      companyName: null,
      imgLink: null,
      origin: "invite",
      branchDados: null,
      convenio: null,
    };
  },
  methods: {
    getCompanyData() {
      return new Promise((resolve) => {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri.toLowerCase());
        var company_id = params.get("company_id");
        var branch_id = params.get("branch_id");
        this.origin = params.get("origin");

        branch_id = branch_id.replace("_hotlink", "");

        getApi(
          `${process.env.VUE_APP_MEUCORBAN_DOC_API_URL}/api/v1/Docs/Typed?userId=${company_id}&type=Senfie`
        ).then((company) => {
          if (company) this.imgLink = company.link;
          getApi(
            `${process.env.VUE_APP_MEUCORBAN_ADMIN_API_URL}/api/Company/${company_id}/Branch/${branch_id}`
          ).then((branch) => {
            this.branchName = branch.Name;
            this.companyName = branch.Company.Name;
            this.branchDados = branch;
            resolve();
          });
        });
      });
    },
    changeTab(tab) {
      this.activeTab = tab;
    },
    offLoading() {
      this.loading = false;
    },
    async setPadrao(force) {
      let currentUrl = window.location.href;
      let url = new URL(currentUrl);
      let params = new URLSearchParams(url.search.toLowerCase());
      if (force) {
        params.delete("convenio");
      }
      if (params.get("convenio") === null) {
        params.set("convenio", "inss");
        url.search = params.toString();
        window.history.pushState({}, "", url.toString());
        await this.loadData();
      }
    },
    getCategory() {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri.toLowerCase());
      let convenio = params.get("convenio");

      return new Promise((resolve) => {
        getApi(
          `${process.env.VUE_APP_MEUCORBAN_CORBAN_API_URL}/api/v1/Category/${convenio}`
        ).then((category) => {
          if (category === null) {
            this.setPadrao(true);
            return;
          }
          this.category = category.description;
          this.category_id = category.id;
          resolve();
        });
      });
    },

    getProduct() {
      return new Promise((resolve) => {
        getApi(
          `${process.env.VUE_APP_MEUCORBAN_CORBAN_API_URL}/api/v1/Category/${this.category_id}/Product?pageIndex=1&pageSize=50`
        ).then((product) => {
          const products = product.data.filter((x) => x.description == "Novo");
          if (products.length > 0) {
            this.product = products[0];
          } else if (product?.data?.length === 0) {
            this.setPadrao(true);
            return;
          } else {
            this.product = product.data[0];
          }
          this.selectedProduct = this.product;
          this.selectedProduct.description = `${this.category}`;
          localStorage.setItem("product", JSON.stringify(this.product));
          this.isLoading = false;
          resolve();
        });
      });
    },
    getToken() {
      return new Promise((resolve) => {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri.toLowerCase());
        this.company_id = params.get("company_id");
        this.branch_id = params.get("branch_id");
        this.origin = params.get("origin");
        if (this.origin === "invite") {
          this.activeTab = "start-invite";
        }
        getOwnerCredentials(this.branch_id + "_hotlink", this.company_id).then(
          (response) => {
            localStorage.setItem("token", response.access_token);
            this.access_token = response.access_token;
            resolve();
          }
        );
      });
    },
    async handleIcon() {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri.toLowerCase());
      this.company_id = params.get("company_id");
      // accessing the link tag
      const favicon = document.getElementById("favicon");
      var response = await getApi(
        `${process.env.VUE_APP_MEUCORBAN_DOC_API_URL}/api/v1/Docs/Typed?userId=${this.company_id}&type=Senfie`
      );
      if (response !== null) {
        favicon.href = response.link;
      }
    },
    async loadData() {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri.toLowerCase());
      this.origin = params.get("origin");
      this.convenio = params.get("convenio") ?? "inss";

      this.setPadrao();

      if (params.get("Convenio") === "") {
        this.setPadrao();
        return;
      }

      this.getToken().then(() => {
        this.handleIcon();
        this.getCompanyData().then(() => {
          this.getCategory().then(() => {
            this.getProduct();
          });
        });
      });
    },
  },

  async mounted() {
    await this.loadData();
  },
};
</script>

<style scoped>
html,
body,
* {
  font-family: "Quicksand", sans-serif;
}

.request-tabs {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.circle {
  position: fixed;
  width: 500px;
  height: 500px;

  border-radius: 50%;
  z-index: -1000;
}

.circle-grey {
  background-color: #98a8b2;
}

.circle-light {
  background-color: #5ccbe195;
}

.circle.top-left {
  left: -75vw;
  top: -20vh;
}

.circle.top-right {
  right: -75vw;
  top: -20vh;
}

.circle.bottom-right {
  right: -65vw;
  bottom: -35vh;
}

.circle.bottom-left {
  left: -60vw;
  bottom: -35vh;
}

.tab {
  display: none;
  flex-direction: column;
  background-image: url("../assets/img/BackgroundInvite.png");
  background-size: cover;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.tab > * {
  z-index: 2;
}

.tab.active {
  display: flex;
}

.tab.category,
.tab.contact {
  background-color: #ffffff;
  color: white;
}
.tab-invite {
  display: none;
  flex-direction: column;
  background-image: url("../assets/img/BackgroundInvite.png");
  background-size: cover;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.tab-invite > * {
  z-index: 2;
}

.tab-invite.active {
  display: flex;
}

.tab-invite.category,
.tab-invite.contact {
  background-color: #ffffff;
  color: white;
}

h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #d6ecfa;
}

.contact h1 {
  margin-left: 150px;
}

.category h1 {
  margin-left: -150px;
}

h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
}

h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #033757;
}

h4 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 30px;
  right: 0;
  background-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 10px;
  text-align: right;
  z-index: 2;
}

.header img {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

small {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #033757;
}

.btn {
  background-color: #033f63;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  border: 0;
  padding: 10px;
  width: 250px;
  border-radius: 0px 20px 20px 20px;
  margin-top: 20px;
}

.btn-circle {
  background-color: #033757;
  padding: 2px 0 0 0;
  border-radius: 100%;
  width: 54px;
  height: 54px;
  margin-top: 60px;
  border: 0;
}

.btn-green {
  background-color: #d8f793;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #033f63;
  border: 0;
  padding: 10px;
  width: 100px;
  border-radius: 0px 20px 20px 20px;
}

.form-group {
  margin-top: 40px;
}

.form-group label {
  position: absolute;
  margin-top: -5px;
  margin-left: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 8px;
  line-height: 10px;
  background: rgba(196, 196, 196, 0.2);
}

.contact .btn {
  margin-top: 50px;
}
</style>

const axios = require("axios");
const oauth = require("axios-oauth-client");
// const tokenProvider = require('axios-token-interceptor');

let uri = window.location.search.substring(1);
let params = new URLSearchParams(uri.toLowerCase());
let company_id = params.get("company_id");
let branch_id = params.get("branch_id");

const getOwnerCredentials = oauth.client(axios.create(), {
  url: `${process.env.VUE_APP_MEUCORBAN_STS_URL}/connect/token`,
  grant_type: "client_credentials",
  client_id: branch_id + "_hotlink",
  client_secret: company_id,
  scope: "doc meucorban_api skoruba_identity_admin_api",
});

// GET
const getApi = async (path, queryString) => {
  let url = "";
  let result = null;

  if (path == null || path == undefined) {
    path = "";
  }
  if (queryString == null || queryString == undefined) {
    url = path;
  } else {
    let query = new URLSearchParams(queryString).toString();
    url = path + "?" + query;
  }

  const token = window.localStorage.getItem("token");

  await fetch(url, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  })
    .then((response) =>
      response.text().then((data) => {
        if (data) {
          result = JSON.parse(data);
        }
      })
    )
    .catch((error) => {
      console.log("ERROR: ", error);
      result = null;
    });

  return result;
};

const postApi = async (path, queryString, body) => {
  let url = "";
  let result = null;

  if (path == null || path == undefined) {
    path = "";
  }
  if (queryString == null || queryString == undefined) {
    url = path;
  } else {
    let query = new URLSearchParams(queryString).toString();
    url = path + "?" + query;
  }

  const token = window.localStorage.getItem("token");

  await fetch(url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) =>
      response.text().then((data) => {
        // console.log(JSON.parse(data))
        result = JSON.parse(data);
      })
    )
    .catch((error) => {
      console.log("ERROR: ", error);
      result = null;
    });

  return result;
};

const putApi = async (path, queryString, body) => {
  let url = "";
  let result = null;

  if (path == null || path == undefined) {
    path = "";
  }
  if (queryString == null || queryString == undefined) {
    url = path;
  } else {
    let query = new URLSearchParams(queryString).toString();
    url = path + "?" + query;
  }

  const token = window.localStorage.getItem("token");

  await fetch(url, {
    method: "PUT",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) =>
      response.text().then((data) => {
        // console.log(JSON.parse(data))
        result = JSON.parse(data);
      })
    )
    .catch((error) => {
      console.log("ERROR: ", error);
      result = null;
    });

  return result;
};
export { getOwnerCredentials, getApi, postApi, putApi };

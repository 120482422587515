<template>
    <div class="header-invite" v-if="imgLink !== null && this.companyName !== null && this.branchName !== null">
        <div class="name-branch">
            <h3>{{ this.companyName !== null ? this.companyName : ""}}</h3>
        </div>
        <img alt="Opmo logo" :src="imgLink" />
    </div>
</template>
<script>

export default {
    props: {
        imgLink: String,
        companyName: String,
        branchName: String,
    },




}
</script>
<style scoped>
.header-invite {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 30px;
    max-width: fit-content;
    height: 60px;
    left: 0;
    background-color: #F5F5FA;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    padding: 10px;
    text-align: left;
    z-index: 2;
}

.header-invite img {
    position: relative;
    left: 15px;
    width: auto;
    height: 75px;
    margin-left: 18px;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
}

.name-branch h3 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    color: #0F91D2;
}
</style>
<template>
  <div class="main-container">
    <div class="title-container">
      <div>
        <img src="../../assets/img/Hand.svg" />
      </div>
      <div class="title">
        <h1>Boas vindas ao</h1>
        <img class="img-title" src="../../assets/img/logo-corban.svg" alt="" />
      </div>
    </div>
    <div class="title-container-branch">
      <div class="title-branch">
        <h1>A sua conexão direta com a</h1>
      </div>
      <div class="info-branchs">
        <div class="title-brch">{{ this.companyName }}</div>
        <img class="img-branch" :src="this.imgLink" />
      </div>
    </div>

    <div class="cta-container">
      <div class="btn-container" v-on:click="changeTab()">
        <button class="btn-page1">Avançar</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    imgLink: String,
    companyName: String,
  },
  methods: {
    changeTab() {
      this.$emit("changeTab", "contact-invite");
    },
  },
};
</script>
<style scoped>
.main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.cta-container {
  align-items: center;
}

.title-container {
  position: relative;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  text-align: center;
}

.title h1 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #5775d0;
}

.img-title {
  position: relative;
  top: 10px;
  right: 0px;
  width: auto;
  height: 39.2px;
}

.btn-container {
  position: relative;
  bottom: 50px;
  display: flex;
  width: 400px;
  justify-content: center;
  align-items: center;
}

.btn {
  background-color: #5775d0;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 35px;
  color: #ffffff;
  border: 0;
  padding: 10px;
  width: 100%;
  border-radius: 0px 20px 20px 20px;
  margin-top: 30px;
}

.title-container-branch {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.title-branch h1 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #525051;
}

.img-branch {
  position: relative;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  left: 10px;
  width: auto;
  height: 70px;
  border-radius: 50%;
}

.title-brch {
  position: relative;
  left: 15px;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  color: #0f91d2;
}

.img-center {
  position: relative;
  width: 100%;
  height: auto;
}

.btn-page1 {
  background-color: #5775d0;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 35px;
  color: #ffffff;
  border: 0;
  padding: 10px;
  width: 80%;
  border-radius: 0px 20px 20px 20px;
  margin-top: 30px;
}

.info-branchs {
  display: flex;
  height: 70px;
  width: 230px;
  margin-top: 6px;
  background-color: #f5f5fa;
  border-radius: 35px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
</style>

<template>
    <div class="header-request" v-if="imgLink !== null && this.companyName !== null && this.branchName !== null">
        <img alt="Opmo logo" :src="imgLink" />
        <div>
            <h3>{{ this.companyName !== null ? this.companyName : ""}}</h3>
            <small>{{ this.branchName !== null ? this.branchName : ""}}</small>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        imgLink: String,
        companyName: String,
        branchName: String,
    },




}
</script>
<style scoped>
.header-request {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 30px;
    max-width: fit-content;
    height: 69px;
    left: 0;
    background-color: #F5F5FA;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
    padding: 10px;
    text-align: left;
    z-index: 2;
}

.header-request h3 {
    position: relative;
    top: 5px;
    margin-right: 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 18.8px;
    color: #5775D0;
}

.header-request small {
    font-style: normal;
    font-weight: 400;
    font-size: 16.5px;

    color: #5775D0
}

.header-request img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
}
</style>
<template>
    <div class="container_splash" v-bind:class="{ 'display': display }">
        <div class="logo-container">
             <div class="img-container">
                <img class="image-logo" :src="this.imgLink" />
            </div>
        </div>
        <img class="gif" src="../../assets/img/Ellipsis.gif" alt="Ellipsi"/>
    </div>
</template>

<script>

export default {
  name: "Splash",
  data() {
    return {
    }
  },
  props: ["display", "imgLink"],
  methods: {
    
  }
}

</script>


<style scoped>

.container_splash{
    position: absolute;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-image: url('../../assets/img/BackgroundInvite.png');
    background-repeat: round;
    opacity: 0;
    visibility: hidden;
    z-index: 15;
}

.gif{
    position: relative;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    top: 38%;
    transform: translate(0, -38%);
    width: 150px;
    height: 150px;
}
.logo-container {
    position: relative;
    top: 44%;
    transform: translate(0, -45%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.logo-container img {
    max-width: calc(100vw - 100px)
}
.display {
    opacity: 1;
    visibility: visible;
}

.img-container {
    margin-top: 40px;
    display: flex;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: none;
    align-items: center;
    justify-content: center;
}

.image-logo {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}
</style>
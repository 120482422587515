<template >
    <div>
        <div class="main-container">
            <div class="title-container">
                <div class="title">
                    <h1>Tudo certo!</h1>
                    <img class="img-title" src="../../assets/img/hand-ok.svg"/>
                </div>
            </div>
            <div class="logo-container">
                <div class="mid-title">
                    <span>Para descobrir tudo o que está disponível para você, baixe o aplicativo:</span>
                </div>
                <img class="img-corban" src="../../assets/img/logo-corban.svg" alt="" />
            </div>
            <div class="links-container">
                <a href="https://app.meucorban.app/PbhJPI">
                    <img class="img-google" src="../../assets/img/Gplay.svg" />
                </a>

                <a href="https://app.meucorban.app/PbhJPI">
                    <img class="img-apple" src="../../assets/img/Apple.svg" />
                </a>
            </div>
        </div>

    </div>
</template>
<script>
import { mask } from 'vue-the-mask'
export default {
    directives: { mask },
    data() {
        return {
            CEP: "",
            Rua: "",
            Número: "",
            Complemento: "",
            Bairro: "",
            Cidade: "",
            Estado: ""
        }
    },

    methods: {

    }
}
</script>
<style scoped>
.logo-container {
    position: relative;
    top: 0;
    margin-bottom: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-container {
    width: 100vw;
    height: 100vh;
    justify-content: space-around;
    align-items: center;
    display: flex;
    flex-direction: column;

}



.tlt {
    position: relative;
    right: 110px;
    bottom: 20px;
}

.title-container {
    position: relative;
    display: flex;
    margin-top: 150px;
    flex-direction: column;
    text-align: center;
}

.title h1 {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #5775D0;
}

.img-title {
    position: relative;
    top: 10px;
    right: -10px;
    width: auto;
    height: 133px;
}

.img-center {
    position: relative;
    top: 110px;
    width: 100%;
    height: auto;
}

.links-container {
    position: relative;
    bottom: 70px;
    display: flex;
    justify-content: space-between;
    max-width: fit-content;
    gap: 20px;
    align-items: center;
}

.img-google {
    position: relative;
    width: 100%;
    height: auto;

}

.img-apple {
    width: 100%;
    height: auto;
}
.mid-title {
    display: flex;
    width: 280px;
    margin-bottom: 40px;
    align-items: center;
    justify-content: center;
}
.mid-title span {
    
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    text-align: center;

    color: #5775D0;
}
.img-corban {
    height: 35px;
    width: auto;
}
</style>
<template>
  <div>
    <SplashInvite v-bind:display="displaySplash" :img-link="this.imgLink" />
    <div class="container">
      <form @submit.prevent="sendAllData">
        <div class="row title">
          <h1 class="col-12">Dados de contato</h1>
        </div>
        <div class="row">
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="icon-name">
                <img
                  class="img img-responsive input-icon"
                  src="../assets/img/nameIcon.svg"
                />
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              placeholder="Nome Completo"
              aria-label="Name"
              aria-describedby="icon-name"
              v-model="name"
            />
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="icon-name">
                <vue-feather
                  class="img-text input-icon"
                  type="credit-card"
                  stroke="#8C8C8C"
                ></vue-feather>
              </span>
            </div>
            <input
              type="tel"
              class="form-control"
              v-mask="['###.###.###-##']"
              placeholder="CPF"
              v-model="cpf"
              required
            />
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="icon-name">
                <vue-feather
                  class="input-icon"
                  type="calendar"
                  stroke="#8C8C8C"
                ></vue-feather>
              </span>
            </div>
            <input
              type="tel"
              class="form-control"
              v-mask="['##/##/####']"
              placeholder="Data de Nascimento"
              v-model="birthdate"
              required
            />
          </div>

          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="icon-name">
                <i class="bi bi-whatsapp"></i>
              </span>
            </div>
            <input
              type="tel"
              class="form-control"
              v-mask="['(##) ####-####', '(##) #####-####']"
              placeholder="Whatsapp"
              v-model="wpp"
              required
            />
          </div>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="icon-name">
                <vue-feather
                  class="input-icon"
                  type="map-pin"
                  stroke="#8C8C8C"
                ></vue-feather>
              </span>
            </div>
            <input
              type="tel"
              class="form-control"
              v-mask="['#####-###']"
              placeholder="CEP de sua residência"
              v-model="CEP"
              v-on:blur="fillAddress()"
              required
            />
          </div>
        </div>
        <div class="row contract mb-5">
          <span
            >Ao avançar, declaro que estou de acordo com o
            <a
              href="https://meucorban.app/termos-de-uso-politica-privacidade/"
              target="_blank"
              >Contrato do MeuCorban</a
            ></span
          >
        </div>

        <div class="row align-items-baseline d-flex justify-content-center">
          <button class="btn" :disabled="hiddenButton">Avançar</button>
        </div>
      </form>
    </div>
    <AlertDanger ref="alertdanger"></AlertDanger>
  </div>
</template>
<script>
import { mask } from "vue-the-mask";
import { postApi, putApi } from "../services/AppService";
import AlertDanger from "../components/AlertDanger.vue";
import SplashInvite from "../components/Invite/SplashInvite.vue";
import { validationCPF } from "../utility/library";
import moment from "moment";

export default {
  directives: { mask },
  data() {
    return {
      name: "",
      cpf: "",
      email: "",
      wpp: "",
      CEP: "",
      Rua: "",
      Bairro: "",
      Cidade: "",
      Estado: "",
      birthdate: "",
      validationError: "",
      displaySplash: false,
      hiddenButton: false,
    };
  },
  components: {
    AlertDanger,
    SplashInvite,
  },
  props: {
    imgLink: String,
    origin: String,
  },
  methods: {
    async sendAllData() {
      this.hiddenButton = true;
      try {
        this.displaySplash = true;
        setTimeout(async () => {
          const user = await this.sendContact();

          await this.sendBranch(user.Id);

          if (this.origin != "invite") {
            this.sendCreditRequest(user.Id);
          }
        }, 2000);
      } catch (e) {
        console.error(e);
      }
    },

    async sendContact() {
      let birth = moment(this.birthdate, "DD/MM/YYYY");
      let dateBirthFormated;
      if (!birth.isValid()) {
        this.birthdate = "";
        this.displaySplash = false;
        return;
      } else {
        dateBirthFormated = birth.toISOString();
      }

      let cpfValidation = this.validation(this.cpf);

      if (cpfValidation == false) {
        this.displaySplash = false;
        this.cpf = "";
        return this.message("Cpf inválido", "danger");
      }

      if (this.name !== "" && this.cpf !== "" && this.wpp !== "") {
        const userSend = await postApi(
          `${process.env.VUE_APP_MEUCORBAN_ADMIN_API_URL}/api/Users`,
          "",
          {
            FullName: this.name,
            PhoneNumber: this.wpp,
            Cpf: this.cpf,
            Birthdate: dateBirthFormated,
            Address: {
              Street: this.Rua,
              Neighborhood: this.Bairro,
              City: this.Cidade,
              State: this.Estado,
              Country: "Brasil",
              ZipCode: this.CEP,
            },
          }
        );

        if (userSend.status) {
          this.displaySplash = false;
          let error = Object.values(userSend.errors)[0];
          this.validationError = error[0];
          this.message(this.validationError, "danger");
          return;
        } else {
          return userSend;
        }
      }
    },
    async sendBranch(Userid) {
      let uri = window.location.search.substring(1);
      let params = new URLSearchParams(uri.toLowerCase());

      var branch_id = params.get("branch_id");
      branch_id = branch_id.replace("_hotlink", "");
      await putApi(
        `${process.env.VUE_APP_MEUCORBAN_ADMIN_API_URL}/api/Users/${Userid}/ActiveBranch/${branch_id}`,
        "",
        {}
      );
      if (this.origin == "invite") {
        this.displaySplash = false;
        this.$emit("changeTab", "endHot-invite");
      }
    },
    async sendCreditRequest(Userid) {
      const product = JSON.parse(localStorage.getItem("product"));
      const prices = JSON.parse(localStorage.getItem("prices"));
      const creditReq = await postApi(
        `${process.env.VUE_APP_MEUCORBAN_CORBAN_API_URL}/api/v1/CreditRequest`,
        "",
        {
          productId: product.id,
          coefficient: prices.seletedPrice.coefficient,
          monthlyFee: prices.seletedPrice.monthlyFee,
          deadline: prices.deadline,
          plotValue: parseFloat(prices.plotValue),
          requesterId: Userid,
          agreementNumber: "",
          bankCode: "",
        }
      );
      this.hiddenButton = false;
      if (!creditReq.status) {
        this.displaySplash = false;
        this.$emit("changeTab", "endhot");
      }
    },
    validation(cpf) {
      return validationCPF(cpf);
    },
    message(text, status) {
      this.$refs.alertdanger.message(text, status);
    },

    fillAddress() {
      if (this.CEP == null || this.CEP == undefined || this.CEP == "") return;
      let self = this;
      let xhr = new XMLHttpRequest();
      xhr.open("GET", "https://api.postmon.com.br/v1/cep/" + this.CEP, true);
      xhr.send();
      xhr.addEventListener("readystatechange", function () {
        if (xhr.readyState == 4 && xhr.status == 200) {
          let response = JSON.parse(xhr.responseText);
          self.Rua = response["logradouro"];
          self.Bairro = response["bairro"];
          self.Cidade = response["cidade"];
          self.Estado = response["estado"];
        }
      });
    },
  },
};
</script>
<style scoped>
.container {
  padding: 36px;
}

.btn {
  background-color: #5775d0;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 35px;
  color: #ffffff;
  border: 0;
  padding: 10px;
  width: 300px;
  border-radius: 0px 20px 20px 20px;
}

.input-group-text {
  height: 40px;
  border-radius: 12px 0px 0px 12px;
  background: #fff;
  border: none;
  outline: none;
}

.input-icon {
  height: 16px;
  width: 16px;
  color: #8c8c8c;
}

i {
  font-size: 16px;
  color: #8c8c8c;
}

input {
  height: 40px;
  border: none;
  outline: none;
  border-radius: 12px;
  background: #fff;
  color: #8c8c8c;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding-left: 0px;
}

.title h1 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;

  color: #5775d0;
}

.btn-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.contract span {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;

  color: #5775d0;
}

.contract span a {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-decoration: underline;

  color: #2f4ba0;
}
</style>
